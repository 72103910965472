import React, { useRef, useEffect } from 'react';
import { css } from 'glamor';

function onViewNode(node, threshold, func) {
  var options = {
    root: null,
    rootMargin: '0px 100%',
    threshold
  };

  var observer = new IntersectionObserver((entries, observer) => {
    if (entries.length > 0 && entries[0].intersectionRatio > 0) {
      func();
      observer.disconnect();
    }
  }, options);

  observer.observe(node);

  return () => observer.disconnect();
}

export default function useVisibility({
  animations,
  delay,
  threshold = 0.7,
  direction = 'up',
  disableIfSmall = false,
  onVisible
} = {}) {
  let elRef = useRef(null);

  useEffect(() => {
    let el = elRef.current;

    function trigger() {
      function run() {
        el.style.opacity = 1;
        el.style.transform = 'translateY(0)';
        onVisible && onVisible();
      }

      if (delay != null) {
        setTimeout(run, delay);
      } else {
        run();
      }
    }

    if ('IntersectionObserver' in window && !(disableIfSmall && window.innerWidth <= 900)) {
      onViewNode(el, threshold, trigger);
    } else {
      trigger();
    }
  }, []);

  let style = { transition: '.7s opacity, .7s transform' };

  if (!animations || animations.includes('opacity')) {
    style.opacity = 0;
  }
  if (!animations || animations.includes('translate')) {
    switch (direction) {
      case 'left':
        style.transform = 'translateX(15px)';
        break;
      case 'right':
        style.transform = 'translateX(-15px)';
        break;
      case 'down':
        style.transform = 'translateY(-15px)';
        break;
      case 'up':
      default:
        style.transform = 'translateY(15px)';
    }
  }

  return { ref: elRef, style };
}
