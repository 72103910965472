import React, { useRef, useEffect, useReducer } from 'react';
import { Link } from 'gatsby';
import { css } from 'glamor';
import {
  View,
  Text,
  Button,
  ButtonLink,
  HeaderText,
  ActionButton
} from '../components/common';
import Layout from '../components/layout';
import Content, { SMALL, MEDIUM, LARGE } from '../components/content';
import PlaceholderImage from '../components/PlaceholderImage';
import { colors, gradients } from '../style';
import useVisibility from '../useVisibility';
import WhenVisible from '../components/WhenVisible';

import ScreenshotMain from '../images/screenshot-main.png';
import ScreenshotMobileFrame from '../images/screenshot-mobile-frame.png';
import ScreenshotBudgeting from '../images/screenshot-budgeting.png';
import ScreenshotBudgetingMobile from '../images/screenshot-budgeting-mobile.png';
import ScreenshotTransactions from '../images/screenshot-transactions.png';
import ScreenshotTransactionsMobile from '../images/screenshot-transactions-mobile.png';
import ScreenshotReports from '../images/screenshot-reports.png';

import DesignMovie from '../images/design.mp4';
import TopBackground from '../images/top-background.svg';
import Superpowers from '../images/superpowers.svg';
import LocalFirst from '../images/local-first.svg';
import FeaturesWave from '../images/features-wave.svg';
import APIIcon from '../images/svg/api.svg';
import MobileIcon from '../images/svg/mobile.svg';
import TransferIcon from '../images/svg/transfer.svg';
import PayeeIcon from '../images/svg/payee.svg';
import SplitIcon from '../images/svg/split.svg';
import ImportIcon from '../images/svg/import.svg';
import WalletIcon from '../images/svg/wallet.svg';
import SyncIcon from '../images/svg/sync.svg';
import UndoIcon from '../images/svg/undo.svg';
import MigrateIcon from '../images/svg/migrate.svg';
import EverythingIcon from '../images/svg/everything.svg';
import PrivacyIcon from '../images/svg/privacy.svg';

function FullFeature({ title, media, opposite, children }) {
  let asset = useRef(null);
  let { ref, style } = useVisibility({
    onVisible: () => {
      if (asset.current) {
        if (media.video) {
          let video = asset.current.querySelector('video');
          video.play();
        }
      }
    }
  });

  return (
    <View
      className={
        'w-full mt-32 md:px-12 items-center flex-col-reverse text-center ' +
        (opposite
          ? 'md:flex-row-reverse md:text-right'
          : 'md:flex-row md:text-left')
      }
      style={style}
      ref={ref}
    >
      <View className={`mt-12 md:mt-0 ${opposite ? 'md:ml-16' : 'md:mr-16'}`}>
        <HeaderText>{title}</HeaderText>
        <div className="mt-4">{children}</div>
      </View>

      <View className="flex-shrink-0">
        {media.image ? (
          <img
            ref={asset}
            src={media.image}
            style={{ width: media.width || 350 }}
          />
        ) : (
          <div
            ref={asset}
            dangerouslySetInnerHTML={{
              __html: `
                  <video
                    loop
                    muted
                    autoplay
                    playsinline
                    src="${media.video}"
                  >
                 </video>
                `
            }}
            style={{ width: 350 }}
          />
        )}
      </View>
    </View>
  );
}

function ScreenshotFeature({
  title,
  id,
  className,
  direction,
  desktopImage,
  mobileImage,
  children
}) {
  let { ref, style } = useVisibility({
    direction,
    threshold: 0.4,
    disableIfSmall: true
  });

  return (
    <View
      ref={ref}
      className={'text-center mt-40 ' + className}
      style={[{ width: 700, [SMALL]: { width: 'auto' } }, style]}
      id={id}
    >
      <HeaderText>{title}</HeaderText>
      <div className="mt-8">{children}</div>

      <View className="mt-12 flex-col md:flex-row relative items-center justify-center">
        <img className="shadow-lg" src={desktopImage} style={{ width: 620 }} />

        {mobileImage && (
          <img
            className="shadow-lg rounded-lg md:ml-12 mt-8 md:mt-0"
            src={mobileImage}
            {...css({
              width: 180,
              [MEDIUM]: { width: '60%' },
              [SMALL]: { width: '80%' }
            })}
          />
        )}
      </View>
    </View>
  );
}

function Feature({ icon: Icon, title, children }) {
  return (
    <div
      className="mb-8 md:mb-0 link-color-inherit"
      style={{ paddingLeft: 40, flex: 1 }}
    >
      <div className="mb-4 relative">
        <Icon
          style={{
            width: 24,
            fill: 'white',
            position: 'absolute',
            top: 0,
            left: -40
          }}
        />
        <strong>{title}</strong>
      </div>
      <div>{children}</div>
    </div>
  );
}

function FeatureRow({ children }) {
  let [left, right] = children;
  return (
    <View className="md:mb-16 flex-col md:flex-row w-full">
      {left}
      <div className="md:pl-16" />
      {right}
    </View>
  );
}

let IndexPage = () => (
  <Layout flushFooter>
    <img
      {...css({
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        width: '100%',
        height: 900,
        [MEDIUM]: { height: 700 }
      })}
      src={TopBackground}
    />
    <Content
      style={{
        paddingBottom: 100,
        alignItems: 'center'
      }}
    >
      <WhenVisible animations={['opacity']}>
        <View
          className="items-center text-center md:px-12 sm:px-0 mt-24 md:mt-32"
          style={{
            width: 850,
            [MEDIUM]: { width: '100%' }
          }}
        >
          <HeaderText
            textSize=""
            className="text-3xl sm:text-4xl md:text-4xl text-gray-800"
          >
            Enjoy managing your finances
          </HeaderText>

          <Text
            className="mx-4 md:mx-8 mt-12"
            style={{ fontSize: 17, textAlign: 'center' }}
          >
            Actual is a super fast privacy-focused app for managing your
            finances. You own your data, and we will sync it across all devices
            with optional end-to-end encryption.
          </Text>
        </View>
      </WhenVisible>

      <WhenVisible>
        <View className="items-center text-center">
          <ActionButton
            to="/pricing"
            text="Try Actual for free"
            onClick={() => mixpanel.track('site:home-primary-action')}
            className="lg:mt-24 mt-16 self-center"
          />

          <Text className="mt-4 text-gray-500 text-sm">
            Available for <a href="https://app.actualbudget.com/">web</a>,
            macOS, Windows, Linux, iOS, Android
          </Text>
        </View>

        <View className="w-full flex-row sm:flex-col mt-24 items-center">
          <View className="sm:mr-24 md:mr-32">
            <img alt="screenshot" src={ScreenshotMain} style={{ width: 800 }} />
          </View>
          <View
            style={{
              width: 185,
              position: 'absolute',
              top: 85,
              right: 95,
              [LARGE]: { top: 50 },
              [MEDIUM]: { width: 135, right: 90 },
              [SMALL]: { width: 200, position: 'relative', top: 0, right: 0 }
            }}
          >
            <div
              {...css({
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'white',
                zIndex: 1,
                transform: 'scale(.9)',
                filter: 'drop-shadow(0 30px 10px rgba(0, 0, 0, .25))',
                [SMALL]: {
                  transform: 'scaleX(.8)',
                  filter: 'drop-shadow(0 3px 7px rgba(0, 0, 0, .25))'
                }
              })}
            />
            <img
              alt="screenshot"
              src={ScreenshotMobileFrame}
              style={{ zIndex: 2 }}
            />
          </View>
        </View>
      </WhenVisible>

      <FullFeature
        title="Be involved in your financial decisions"
        media={{ image: Superpowers }}
      >
        Automated finance tools are great, except when they aren’t. We provide
        you with tools that are quick to use, but ultimately{' '}
        <strong>you are in control</strong>. We help you learn, instead of
        dictating.
      </FullFeature>

      <FullFeature
        title="Meticulously designed for speed"
        media={{ video: DesignMovie }}
        opposite
      >
        A <strong>beautifully designed interface</strong> is fine-tuned to get
        out of your way and make it as fast as possible to explore your
        finances.
      </FullFeature>

      <FullFeature
        title="Unabashedly local-first software"
        media={{ image: LocalFirst, width: 300 }}
      >
        <strong>Actual is a local app, plain and simple.</strong> Your data is
        synced in the background so all devices have access, but the app totally
        works regardless of your network connection. This also allows{' '}
        <a href="/docs/overview/syncing-across-devices/#end-to-end-encryption">
          end-to-end encryption
        </a>{' '}
        to keep your data private.
      </FullFeature>

      <ScreenshotFeature
        title="Powerful budgeting made simple"
        desktopImage={ScreenshotBudgeting}
        mobileImage={ScreenshotBudgetingMobile}
        className="pt-10"
        direction="right"
        id="features"
      >
        <strong>
          Save hundreds of dollars a year (at least!) by tracking your spending.
        </strong>{' '}
        Based on tried and true methods, our budgeting system is based off of
        your real income instead of made up numbers. This makes you face your
        real spending, and clearly shows how much you are saving each month. We
        make this process as simple as possible.{' '}
        <Link to="/docs/budgeting/how-it-works">Learn more</Link>
      </ScreenshotFeature>

      <ScreenshotFeature
        title="The fastest way to manage transactions"
        desktopImage={ScreenshotTransactions}
        mobileImage={ScreenshotTransactionsMobile}
        direction="left"
      >
        Breeze through your transactions and update them easily with a
        streamlined, minimal interface. Categorizing your transactions correctly
        is important and we’ve optimized this process. Manage split transactions
        and transfers all in the same editor.{' '}
      </ScreenshotFeature>

      <ScreenshotFeature
        title="Oh my, the reports"
        direction="right"
        desktopImage={ScreenshotReports}
      >
        Intuitive reports give you a quick way to learn about your finances. By
        default, we include net worth and cash flow reports, but soon you’ll be
        able to create any report that you like. You’ll even be able to download
        custom reports from others.
      </ScreenshotFeature>
    </Content>

    <View
      style={{
        background: 'linear-gradient(180deg, #242B6B 0%, #1B2152 100%)'
      }}
    >
      <img
        src={FeaturesWave}
        {...css({
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          width: '100%',
          height: 350
        })}
      />

      <Content
        className="items-center text-white"
        style={{
          paddingBottom: 100,
          [SMALL]: {
            paddingBottom: 0
          }
        }}
      >
        <HeaderText className="mt-32 mb-20">So many features</HeaderText>

        <FeatureRow>
          <Feature icon={EverythingIcon} title="Everything in one place">
            Add all of your accounts and track everything in one place. Get
            valuable information like net worth from all your accounts together.{' '}
            <a href="/docs/accounts/overview/">Learn more</a>
          </Feature>
          <Feature icon={MobileIcon} title="Mobile app">
            A proper mobile app is critical to stay engaged with your finances.
            We've built a native app from the ground up with mobile interactions
            in mind.
          </Feature>
        </FeatureRow>
        <FeatureRow>
          <Feature icon={PayeeIcon} title="Payees">
            Manage payees quickly. Merge them, set up custom rules for how they
            are matched, and use default categories to automatically categorize
            transactions. <a href="/docs/accounts/payees/">Learn more</a>
          </Feature>
          <Feature icon={SplitIcon} title="Split transactions">
            Use split transactions to separate a single expense into multiple
            categories. An inline editor makes this seamless.{' '}
            <a href="/docs/accounts/importing-transactions/">Learn more</a>
          </Feature>
        </FeatureRow>
        <FeatureRow>
          <Feature icon={WalletIcon} title="Budgeting">
            Track your spending with budgets. You can only budget cash you have
            on hand, which means your budget stays realistic and you don't make
            numbers up. <a href="/docs/budgeting/how-it-works/">Learn more</a>
          </Feature>

          <Feature icon={TransferIcon} title="Transfers">
            Manage transfers easily by creating transfer transactions. Actual
            will link the transactions on both sides and update them together.{' '}
            <a href="/docs/accounts/transfers/">Learn more</a>
          </Feature>
        </FeatureRow>
        <FeatureRow>
          <Feature icon={ImportIcon} title="Importing Transactions">
            Import transactions from the most popular financial files: QIF, OFX,
            QFX, and CSV. Soon we will launch bank syncing to automatically
            download transactions.{' '}
            <a href="/docs/accounts/importing-transactions/">Learn more</a>
          </Feature>
          <Feature icon={UndoIcon} title="Undo & redo">
            A robust undo system allows you to rollback any changes you make,
            and redo them if desired. Never worry about making mistakes.{' '}
            <a href="/docs/overview/tips-&-tricks/#undoredo">Learn more</a>
          </Feature>
        </FeatureRow>

        <FeatureRow>
          <Feature icon={MigrateIcon} title="Migrate your data">
            We provide a builtin YNAB4 importer that keeps all of your history
            (it{"'"}s even{' '}
            <a href="https://github.com/actualbudget/import-ynab4">
              open source
            </a>
            ). Importing from other apps is coming soon.{' '}
            <a href="/docs/overview/migrating-from-other-apps/">Learn more</a>
          </Feature>
          <Feature icon={APIIcon} title="API">
            If you're a developer, we got you. Use our fully-featured API to
            write custom importers or build your own features. This API simply
            runs on your local data.{' '}
            <a href="/docs/developers/using-the-API/">Learn more</a>
          </Feature>
        </FeatureRow>
        <FeatureRow>
          <Feature icon={SyncIcon} title="Syncing across devices">
            We offer a syncing service which uses sophisticated distributed
            systems technology to sync changes across any number of devices.{' '}
            <a href="/docs/overview/syncing-across-devices/">Learn more</a>
          </Feature>

          <Feature icon={PrivacyIcon} title="End-to-end encryption">
            We offer a syncing service which uses sophisticated distributed
            systems technology to sync changes across any number of devices.{' '}
            <a href="/docs/overview/syncing-across-devices/#end-to-end-encryption">
              Learn more
            </a>
          </Feature>
        </FeatureRow>

        <View className="mt-20 mb-24 items-center">
          <HeaderText className="mb-8">Your data is protected</HeaderText>
          <View
            className="flex-row mx-4 sm:mx-0"
            style={{ width: 600, [SMALL]: { width: 'auto' } }}
          >
            <PrivacyIcon
              className="mr-8"
              style={{ width: 50, flexShrink: 0, fill: 'white' }}
            />
            <Text className="link-color-inherit">
              While Actual is a local app, changes are stored on our servers.
              This lets you pull down changes from anywhere. We use{' '}
              <strong>bank-level 256-bit AES encryption</strong> to store your
              data. We also offer end-to-end encryption which means even we
              can't read your data.{' '}
              <a href="/docs/overview/syncing-across-devices">Learn more</a>
            </Text>
          </View>
        </View>
      </Content>
    </View>

    <Content className="items-center">
      <HeaderText className="mt-32 mb-8 text-center">
        Ready to get started?
      </HeaderText>
      <ActionButton to="/pricing" text="Try Actual for free" />

      <a
        href="https://www.producthunt.com/posts/actual-budget?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-actual-budget"
        target="_blank"
        className="mt-12 mb-12"
      >
        <img
          src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=143790&theme=light&period=daily"
          alt="Actual Budget - Robust and simple budgeting that works | Product Hunt Embed"
          style={{ width: 250, height: 54 }}
          width={250}
          height={54}
        />
      </a>
    </Content>
  </Layout>
);

export default IndexPage;
