import React from 'react';
import { View } from './common';

function PlaceholderImage({ width = 100, height = 100, style }) {
  return (
    <View
      style={[
        {
          width,
          height,
          backgroundColor: 'rgba(150, 150, 150, .1)',
          borderRadius: 2000
        },
        style
      ]}
    />
  );
}

export default PlaceholderImage;
