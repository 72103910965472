import React from 'react';
import { View } from './common';
import useVisibility from '../useVisibility';

export default function WhenVisible({ children, animations, delay }) {
  let { ref, style } = useVisibility({ animations, delay });

  return (
    <View ref={ref} style={style}>
      {children}
    </View>
  );
}
